*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%; }
  @media only screen and (min-width: 2500px) {
    html {
      font-size: 90%; } }
  @media only screen and (max-width: 900px) {
    html {
      font-size: 55%; } }
  @media only screen and (max-width: 600px) {
    html {
      font-size: 50%; } }
  @media only screen and (max-width: 400px) {
    html {
      font-size: 40%; } }

body {
  font-weight: 500;
  line-height: 2;
  font-family: "Times New Roman", Times, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.mainPage {
  padding-top: 3rem; }

.navbar {
  background-color: blue;
  display: flex;
  padding: 5px 10px;
  justify-content: space-between;
  box-shadow: 0 10px 40px 5px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  position: sticky;
  top: 0;
  z-index: 100; }

.navButton {
  text-decoration: none;
  border: none;
  background-color: inherit;
  cursor: pointer;
  color: #FFFFFF;
  border-radius: 100px;
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 1px; }
  .navButton:hover {
    background-color: red; }

.footer {
  text-align: center;
  background-color: blue;
  color: white;
  padding: .5rem;
  font-size: 2rem; }

.imageContainer {
  transform: translate(0px, -21px); }

.mainImage {
  height: 30rem;
  object-fit: cover; }
  @media only screen and (min-width: 2000px) {
    .mainImage {
      height: 50rem; } }
  @media only screen and (max-width: 430px) {
    .mainImage {
      height: 25rem; } }
  .mainImage-photo {
    height: 100%;
    width: 100%;
    object-fit: cover; }

.coverImage {
  width: 20rem;
  height: 20rem;
  clip-path: circle(50% at 50% 50%);
  position: absolute;
  top: 26rem;
  left: 27rem;
  transform: translate(-50%, -50%); }
  @media only screen and (min-width: 2000px) {
    .coverImage {
      top: 48rem;
      width: 30rem;
      height: 30rem;
      left: 41rem; } }
  @media only screen and (max-width: 1160px) {
    .coverImage {
      left: 15rem; } }
  @media only screen and (max-width: 600px) {
    .coverImage {
      left: 11rem;
      top: 28rem;
      width: 15rem;
      height: 15rem; } }
  @media only screen and (max-width: 430px) {
    .coverImage {
      left: 7rem;
      top: 23rem;
      width: 10rem;
      height: 10rem; } }
  .coverImage-photo {
    height: 100%;
    transform: translate(-20px, 0px); }
    @media only screen and (min-width: 2000px) {
      .coverImage-photo {
        transform: translate(-42px, 0px); } }
    @media only screen and (max-width: 600px) {
      .coverImage-photo {
        transform: translate(-10px, 0px); } }

.aboutMeContainer {
  width: 50%;
  /* position: absolute; */
  /* top: 1rem; */
  /* left: 42rem; */
  transform: translate(80%, -2rem);
  margin-bottom: 4rem; }
  @media only screen and (max-width: 600px) {
    .aboutMeContainer {
      transform: translate(50%, -2rem);
      width: 65%; } }
  @media only screen and (max-width: 430px) {
    .aboutMeContainer {
      transform: translate(10%, -2rem);
      width: 90%; } }

.aboutMe-header {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 3.3rem; }

.aboutMe-body {
  font-size: 2.3rem; }

.projectsContainer {
  display: flex; }
  @media only screen and (max-width: 1200px) {
    .projectsContainer {
      display: block; } }

.projects {
  margin-top: 20rem;
  margin-bottom: 15rem; }
  @media only screen and (min-width: 2000px) {
    .projects {
      margin-top: 40rem; } }
  .projects-title {
    text-align: center;
    margin-bottom: 5rem;
    font-size: 3.3rem; }

.row {
  margin-right: 2rem; }
  @media only screen and (max-width: 600px) {
    .row {
      margin-right: 0; } }

.projectContainer {
  display: flex;
  margin-bottom: 5rem;
  justify-content: space-around; }
  @media only screen and (min-width: 1800px) {
    .projectContainer {
      margin-bottom: 10rem; } }
  @media only screen and (max-width: 600px) {
    .projectContainer {
      justify-content: flex-start; } }

.project {
  width: 70%;
  padding-left: 2rem; }
  @media only screen and (min-width: 1800px) {
    .project {
      padding-left: 2rem; } }
  .project-shape {
    width: 14rem;
    height: 10rem; }
    @media only screen and (min-width: 1800px) {
      .project-shape {
        width: 24rem;
        height: 18rem; } }
    @media only screen and (max-width: 600px) {
      .project-shape {
        margin-left: 1rem; } }
    .project-shape-img {
      width: 100%;
      height: 100%; }
  .project-name {
    text-align: center;
    margin-bottom: 1.5rem;
    line-height: 1rem;
    font-size: 2.5rem; }
    @media only screen and (min-width: 1800px) {
      .project-name {
        margin-bottom: 3.5rem;
        line-height: 2rem; } }
  .project-description {
    margin-bottom: .5rem;
    font-size: 2rem; }
  .project-links {
    display: flex;
    justify-content: space-around;
    font-size: 2rem; }

.contactContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 40rem; }
  @media only screen and (min-width: 2000px) {
    .contactContainer {
      height: 70rem; } }
  @media only screen and (min-width: 1500px) {
    .contactContainer {
      height: 60rem; } }

.contact-title {
  margin-bottom: 6rem;
  padding-top: 3rem;
  font-size: 3rem; }
  @media only screen and (min-width: 1800px) {
    .contact-title {
      font-size: 4rem; } }

.contact-img {
  background-image: url(../img/projects/map.JPG);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: -27rem;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  filter: grayscale(1);
  opacity: .5; }
  @media only screen and (min-width: 2000px) {
    .contact-img {
      background-position-y: -65rem; } }
  @media only screen and (min-width: 2500px) {
    .contact-img {
      background-position-y: -40rem; } }
  @media only screen and (max-width: 1135px) {
    .contact-img {
      background-position-y: -15rem; } }
  @media only screen and (max-width: 900px) {
    .contact-img {
      background-position-y: 0; } }

.contact-body-item {
  display: flex;
  margin-bottom: 4rem;
  font-size: 2rem;
  line-height: 1rem;
  align-items: center; }
  @media only screen and (min-width: 1800px) {
    .contact-body-item {
      font-size: 3rem; } }

.contact-body-icon {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem; }
  @media only screen and (min-width: 1800px) {
    .contact-body-icon {
      width: 4rem;
      height: 4rem; } }
  .contact-body-icon-img {
    height: 100%;
    width: 100%; }

.contact-body-info {
  margin-left: .5rem; }

.contact-body-link {
  color: black; }

.skills {
  padding: 1rem;
  border: 3px solid blue;
  top: 52rem;
  position: absolute;
  left: 5rem; }
  @media only screen and (min-width: 2000px) {
    .skills {
      left: 8rem;
      top: 88rem; } }
  @media only screen and (max-width: 1800px) {
    .skills {
      left: 1rem; } }
  @media only screen and (max-width: 1700px) {
    .skills {
      position: relative;
      top: 0;
      left: 0;
      margin: 0 1rem; } }
  .skills-line {
    display: flex;
    justify-content: space-around; }
  .skills-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 2rem; }
    @media only screen and (min-width: 2000px) {
      .skills-container {
        margin-right: 3rem; } }
    @media only screen and (max-width: 900px) {
      .skills-container {
        margin-right: 2rem; } }
    @media only screen and (max-width: 600px) {
      .skills-container {
        margin-right: 1.5rem; } }
    @media only screen and (max-width: 370px) {
      .skills-container {
        margin-right: 1rem; } }
    @media only screen and (max-width: 340px) {
      .skills-container {
        margin-right: .5rem; } }
  .skills-imgContainer {
    height: 6rem;
    width: 6rem; }
    .skills-imgContainer-img {
      height: 100%;
      width: 100%; }
    @media only screen and (max-width: 900px) {
      .skills-imgContainer {
        height: 4rem;
        width: 4rem; } }
    @media only screen and (max-width: 450px) {
      .skills-imgContainer {
        height: 3rem;
        width: 3rem; } }
  .skills-name {
    font-size: 2rem;
    margin-top: .5rem; }
  .skills-title {
    position: absolute;
    top: -44px;
    left: 39%;
    background-color: white;
    padding: 0 .5rem;
    font-size: 2.3rem; }
    @media only screen and (min-width: 2500px) {
      .skills-title {
        top: -63px; } }
    @media only screen and (min-width: 2000px) {
      .skills-title {
        top: -42px;
        left: 43%; } }
    @media only screen and (max-width: 1700px) {
      .skills-title {
        left: 46%; } }
    @media only screen and (max-width: 1300px) {
      .skills-title {
        left: 45%; } }
    @media only screen and (max-width: 600px) {
      .skills-title {
        top: -38px;
        left: 35%; } }
    @media only screen and (max-width: 600px) {
      .skills-title {
        top: -27px; } }

.resumeContainer {
  position: absolute;
  top: 40rem;
  left: 21rem;
  border: 2px solid blue;
  padding: .5rem;
  width: 14rem; }
  @media only screen and (min-width: 2000px) {
    .resumeContainer {
      top: 70rem;
      left: 32rem;
      width: 16rem; } }
  @media only screen and (max-width: 1160px) {
    .resumeContainer {
      left: 9rem; } }
  @media only screen and (max-width: 700px) {
    .resumeContainer {
      left: 6rem; } }
  @media only screen and (max-width: 600px) {
    .resumeContainer {
      left: 2rem; } }
  @media only screen and (max-width: 550px) {
    .resumeContainer {
      left: 1rem; } }
  @media only screen and (max-width: 430px) {
    .resumeContainer {
      top: 33rem;
      padding: 0; } }
  @media only screen and (max-width: 400px) {
    .resumeContainer {
      padding: 0.5rem; } }
  @media only screen and (max-width: 340px) {
    .resumeContainer {
      left: 0; } }

.resume-title {
  position: absolute;
  top: -15px;
  left: 5rem;
  background-color: white;
  font-size: 1.5rem; }
  @media only screen and (min-width: 2000px) {
    .resume-title {
      left: 6rem;
      top: -24px; } }
  @media only screen and (max-width: 2000px) {
    .resume-title {
      left: 5rem; } }
  @media only screen and (max-width: 600px) {
    .resume-title {
      top: -17px; } }
  @media only screen and (max-width: 550px) {
    .resume-title {
      left: 3rem; } }
  @media only screen and (max-width: 400px) {
    .resume-title {
      top: -12px; } }

.resume-body {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 1.5rem; }
  .resume-body-icon {
    display: block;
    width: 1.6rem;
    height: 1.7rem; }
    @media only screen and (min-width: 2000px) {
      .resume-body-icon {
        width: 2rem;
        height: 2.3rem; } }
    @media only screen and (max-width: 550px) {
      .resume-body-icon {
        display: none; } }
  .resume-body-img {
    width: 100%;
    height: 100%; }
  .resume-body-view {
    z-index: 100; }
  .resume-body-download {
    z-index: 100; }
